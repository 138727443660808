


















































































import { Component, Vue } from "vue-property-decorator";
import Mixin from "@/js/mixins";

@Component({ mixins: [Mixin] })
export default class BuyBuy extends Vue {
  public address: any = null; // 收货地址信息
  public goods: any = null; // 商品信息
  public totle: any = ""; // 商品总额

  public user_id = "";
  public order_id = ""; // 下单成功后的order_id

  public showSuccess = false; // 是否显示下单成功页面

  public url = '';//跳转路径

  public locaId:any = '';//缓存中用户店铺id(当前浏览的店铺)

  // 确认下单
  buy() {
    let _this = this;
    this.$api.request({
      url: "common/buy/buy-order",
      data: { user_id: this.user_id },
      success(res) {
        _this.order_id = res.data.order_id;
        _this.showSuccess = true; // 显示下单成功页面
        console.log(res)
      }
    });
  }

  init() {
    let address = this.$route.query.address;
    let goods = this.$route.query.goods;
    let totle = this.$route.query.totle;
    let user_id = this.$route.query.user_id;
    this.locaId = localStorage.getItem('nowUserId');
    this.url = `/home/shop?id=${this.locaId}`
    if (address && goods && totle && user_id && address instanceof Object) {
      this.address = address;
      this.goods = goods;
      this.totle = totle;
      this.user_id = String(user_id);
      this.order_id = "";
      this.showSuccess = false;
    }
  }
}
